import { Route, Switch } from "react-router-dom";
import { useEffect, useState } from "react";
import { ISite, ISiteV2 } from "../../../../interfaces/Site";
import { IError } from "../../../../../App";
import { getEditQuickStartSite, getEditSite } from "../../../../services/SiteService";
import Loading from "../../../../components/Loading/Loading";
import ShowError from "../../../../components/ShowError/ShowError";
import { isSiteV2 } from "../../../../utils/isSiteV2";
import { ViewRvspLegacy } from "./components/ViewRvspLegacy/ViewRvsp";
import { ActionBarItem } from "../../../../modules/actionBar/components/ActionBarItem/ActionBarItem";
import { ActionBar } from "../../../../modules/actionBar/ActionBar";
import IconEnvelope from "../../../../icons/envelope";
import IconQuestion from "../../../../icons/question";
import { IconExport } from "../../../../icons/export";
import { TLanguageCode, TTranslation } from "../../../../interfaces/IContentData";
import { IFormElementOption, TFormElement } from "../../../../interfaces/FormElement";
import { getRsvps } from "../../../../services/RsvpService";
import { RsvpAnswers } from "./components/RsvpAnswers/RsvpAnswers";
import { formatRsvpsForExport } from "./utils/formatRsvpsForExport";
import writeXlsxFile from "write-excel-file";
import Axios from "axios";
import { RsvpQuestions } from "./components/RsvpQuestions/RsvpQuestions";
import { generateDemoRsvps } from "./utils/generateDemoRsvps";
import { useEditSiteNavigationBasePath } from "../../../../utils/useEditSiteNavigationBasePath";
import { getBrandedQuickstartHost } from "../../../../utils/getBrand";

interface IProps {
    quickStart: boolean;
    siteId?: string;
}

export interface IRsvpFormElement {
    formElement: TFormElement;
    selectedOption?: IFormElementOption;
    value?: string;
}

export interface IRsvpV2 {
    _id: string;
    language: TLanguageCode;
    groupIdentifier: string;
    formId: string;
    formTitle: TTranslation;
    recipients: string[];
    formElements: IRsvpFormElement[];
    createdAt: string;
}

export const ViewRsvp = (props: IProps) => {
    const [loading, setLoading] = useState(true);
    const [rsvpLoading, setRsvpLoading] = useState(true);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [site, setSite] = useState<ISite | ISiteV2 | null>(null);
    const [rsvps, setRsvps] = useState<IRsvpV2[] | null>(null);
    const [rsvpsIsDemo, setRsvpsIsDemo] = useState(false);
    const [error, setError] = useState<IError | null>(null);

    const navigationBasePath = useEditSiteNavigationBasePath();

    useEffect(() => {
        const getSite = async () => {
            setLoading(true);
            try {
                let site;
                if (props.quickStart !== true) {
                    if (!props.siteId) {
                        throw new Error("No siteId but not quickstart");
                    }
                    site = await getEditSite(props.siteId);
                } else {
                    site = (await getEditQuickStartSite(getBrandedQuickstartHost())).site;
                }
                setSite(site);
            } catch (error) {
                setError({ text: "Misslyckades att hämta sidan", response: (error as any).response });
            }
            setLoading(false);
        };
        getSite();
    }, [props.siteId, props.quickStart]);

    useEffect(() => {
        const getData = async () => {
            setRsvpLoading(true);
            try {
                let rsvps: IRsvpV2[];
                if (props.quickStart !== true) {
                    if (!props.siteId) {
                        throw new Error("No siteId but not quickstart");
                    }
                    rsvps = await getRsvps(props.siteId);
                } else {
                    rsvps = [];
                }
                if (rsvps.length === 0) {
                    rsvps = generateDemoRsvps();
                    setRsvpsIsDemo(true);
                }
                setRsvps(rsvps);
            } catch (error) {
                setError({ text: "Misslyckades att hämta OSA-svar", response: (error as any).response });
            }
            setRsvpLoading(false);
        };
        getData();
    }, [props.siteId, props.quickStart]);

    const deleteRsvpItem = async (deleteId: string) => {
        if (rsvpsIsDemo === true) {
            return;
        }
        setDeleteLoading(true);
        await Axios.delete(process.env.REACT_APP_API_URL + "/api/rsvps/" + props.siteId + "/v4/" + deleteId)
            .then((response) => {
                if (rsvps?.length) {
                    const newRsvps = [...rsvps].filter((rsvp) => rsvp._id !== deleteId);
                    setRsvps(newRsvps);
                    setDeleteLoading(false);
                }
            })
            .catch((err) => {
                setDeleteLoading(false);
                setError(err);
            });
    };

    if (loading || rsvpLoading) {
        return <Loading fixed={true} inverted={true} />;
    }

    if (error) {
        return <ShowError error={error} />;
    }

    if (!site) {
        throw Error("No site without loading state or error");
    }

    if (!rsvps) {
        throw Error("No rsvps without loading state or error");
    }

    if (!isSiteV2(site) && !props.quickStart) {
        return <ViewRvspLegacy siteId={props.siteId} quickStart={props.quickStart} />;
    }

    const exportToExcel = async () => {
        const data = formatRsvpsForExport(rsvps);
        await writeXlsxFile(data, {
            fileName: "osa.xlsx",
        });
    };

    const basePathInActionBar = navigationBasePath + "visa-osa/";

    return (
        <div>
            {deleteLoading === true ? <Loading fixed={true} /> : null}
            <ActionBar primary={true}>
                <ActionBarItem text="Svar" icon={IconEnvelope()} to={basePathInActionBar} />
                <ActionBarItem text="Frågor" icon={IconQuestion()} to={basePathInActionBar + "fragor"} />
                <ActionBarItem text="Exportera till excel" icon={IconExport()} active={false} onClick={exportToExcel} />
            </ActionBar>
            <Switch>
                <Route
                    exact
                    path="/redigera/:siteId/visa-osa"
                    render={(routeProps) => (
                        <RsvpAnswers deleteRsvpItem={deleteRsvpItem} demo={rsvpsIsDemo} rsvps={rsvps} />
                    )}
                />
                <Route exact path="/(sms|digital-studentinbjudan)?/snabbstart/visa-osa">
                    <RsvpAnswers deleteRsvpItem={deleteRsvpItem} demo={rsvpsIsDemo} rsvps={rsvps} />
                </Route>
                <Route exact path="/(sms|digital-studentinbjudan)?/snabbstart/visa-osa/fragor">
                    <RsvpQuestions demo={rsvpsIsDemo} rsvps={rsvps} />
                </Route>
                <Route exact path="/kom-igang/redigera/visa-osa">
                    <RsvpAnswers deleteRsvpItem={deleteRsvpItem} demo={rsvpsIsDemo} rsvps={rsvps} />
                </Route>
                <Route exact path="/kom-igang/redigera/visa-osa/fragor">
                    <RsvpQuestions demo={rsvpsIsDemo} rsvps={rsvps} />
                </Route>
                <Route
                    exact
                    path="/redigera/:siteId/visa-osa/fragor"
                    render={(routeProps) => <RsvpQuestions demo={rsvpsIsDemo} rsvps={rsvps} />}
                />
            </Switch>
        </div>
    );
};
