import Axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import FormWrapper from "../../components/form/FormWrapper/FormWrapper";
import PasswordField from "../../components/form/PasswordField/PasswordField";
import TextField from "../../components/form/TextField/TextField";
import { ISite } from "../../interfaces/Site";
import styles from "./Login.module.scss";

interface IProps {
    onSuccess: any;
    showError: any;
}

interface IState {
    email: String;
    password: String;
    loggedInUserSite?: ISite;
    loggedInMail?: String;
}

export class Login extends React.Component<IProps> {
    state: IState = {
        email: "",
        password: "",
    };

    onChangeEmail = (email: String) => {
        this.setState({ email });
    };

    onChangePassword = (password: String) => {
        this.setState({ password });
    };

    componentDidMount = () => {
        this.getLoggedInUser();
        this.setIgnoreTrackingForAdmin();
    };

    setIgnoreTrackingForAdmin = () => {
        try {
            const isAdmin = document.cookie.split(";").some((item) => item.includes("ignoreTracking=1"));
            if (isAdmin) {
                localStorage.setItem("umami.disabled", "1");
            }
        } catch (e) {}
    };

    getLoggedInUser = () => {
        Axios.get(process.env.REACT_APP_API_URL + "/api/accounts/me/")
            .then((response) => {
                this.setState({
                    loggedInUserSite: response.data.site,
                    loggedInMail: response.data.mail,
                });
            })
            .catch((error) => {
                // Do nothing, the user must login
            });
    };

    submitLogin = () => {
        Axios.post(process.env.REACT_APP_API_URL + "/api/accounts/login/", {
            password: this.state.password,
            mail: this.state.email,
        })
            .then((response) => {
                this.props.onSuccess(response.data);
            })
            .catch((error) => {
                this.props.showError("Misslyckades att logga in", error.response);
            });
    };

    logout = () => {
        Axios.post(process.env.REACT_APP_API_URL + "/api/accounts/logout/")
            .then((response) => {
                this.setState({
                    loggedInUserSite: null,
                    loggedInMail: null,
                });
            })
            .catch((error) => {
                this.props.showError("Misslyckades att logga ut", error.response);
            });
    };

    render() {
        return (
            <div className={styles.wrapper}>
                {this.state.loggedInUserSite ? (
                    <div>
                        <div>Du är redan inloggad som {this.state.loggedInMail}.</div>
                        <div className={styles.goToPageOrLogoutWrapper}>
                            <Link
                                className={styles.button}
                                data-test-id="login-page-go-to-my-page"
                                to={"/redigera/" + this.state.loggedInUserSite._id}
                            >
                                Gå till min sida
                            </Link>
                            <div className={styles.logout} onClick={this.logout}>
                                ... eller logga ut
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <FormWrapper submit={this.submitLogin.bind(this)}>
                            <TextField
                                required={true}
                                label="E-post"
                                name="email"
                                id="email"
                                onChangeCallback={this.onChangeEmail.bind(this)}
                            ></TextField>
                            <PasswordField
                                required={true}
                                label="Lösenord"
                                name="password"
                                id="password"
                                onChangeCallback={this.onChangePassword.bind(this)}
                            ></PasswordField>
                            <input
                                data-test-id="button-login"
                                className={styles.button}
                                type="submit"
                                value="Logga in"
                            />
                        </FormWrapper>
                        <div className={styles.links}>
                            <Link to="/aterstall-losenord">Glömt lösenord</Link>
                            {process.env.REACT_APP_BRAND === "wedding" ? (
                                <Link to={"/kom-igang"}>Skapa konto</Link>
                            ) : (
                                <Link to={"/snabbstart?registerAccount=true"}>Skapa konto</Link>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Login;
