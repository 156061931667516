import { Route, Switch } from "react-router-dom";
import { SelectProduct } from "./components/SelectProduct/SelectProduct";
import { useEffect, useState } from "react";
import { ISite, ISiteV2 } from "../../../../interfaces/Site";
import { IError } from "../../../../../App";
import { EditPrint } from "./components/EditPrint/EditPrint";
import { getEditQuickStartSite, getEditSite } from "../../../../services/SiteService";
import Loading from "../../../../components/Loading/Loading";
import ShowError from "../../../../components/ShowError/ShowError";
import { getBrandedQuickstartHost } from "../../../../utils/getBrand";

interface IProps {
    quickStart: boolean;
    drawerSlotPortalNode: JSX.Element;
    siteId?: string;
    host?: string;
}

export const ViewCommunication = (props: IProps) => {
    const [loading, setLoading] = useState(true);
    const [site, setSite] = useState<ISite | ISiteV2 | null>(null);
    const [error, setError] = useState<IError | null>(null);

    useEffect(() => {
        const getSite = async () => {
            setLoading(true);
            try {
                let site;
                if (props.quickStart !== true) {
                    if (!props.siteId) {
                        throw new Error("No siteId but not quickstart");
                    }
                    site = await getEditSite(props.siteId);
                } else {
                    site = (await getEditQuickStartSite(getBrandedQuickstartHost())).site;
                }
                setSite(site);
            } catch (error) {
                setError({ text: "Misslyckades att hämta sidan", response: (error as any).response });
            }
            setLoading(false);
        };
        getSite();
    }, [props.siteId, props.quickStart]);

    if (loading) {
        return <Loading fixed={true} inverted={true} />;
    }

    if (error) {
        return <ShowError error={error} />;
    }

    if (!site) {
        throw Error("No site without loading state or error");
    }

    return (
        <Switch>
            <Route
                path="/redigera/:siteId/inbjudningar/nytt"
                render={(routeProps) => (
                    <EditPrint
                        site={site}
                        quickStart={false}
                        drawerSlotPortalNode={props.drawerSlotPortalNode}
                        printId="new"
                        routerHistory={routeProps.history}
                    />
                )}
            />
            <Route
                path="/(sms|digital-studentinbjudan)?/snabbstart/inbjudningar/nytt"
                render={(routeProps) => (
                    <EditPrint
                        site={site}
                        quickStart={true}
                        drawerSlotPortalNode={props.drawerSlotPortalNode}
                        printId="new"
                        routerHistory={routeProps.history}
                    />
                )}
            />
            <Route
                path="/kom-igang/redigera/inbjudningar/nytt"
                render={(routeProps) => (
                    <EditPrint
                        site={site}
                        quickStart={true}
                        drawerSlotPortalNode={props.drawerSlotPortalNode}
                        printId="new"
                        routerHistory={routeProps.history}
                    />
                )}
            />
            <Route
                path="/redigera/:siteId/inbjudningar/:printId"
                render={(routeProps) => (
                    <EditPrint
                        site={site}
                        quickStart={props.quickStart}
                        drawerSlotPortalNode={props.drawerSlotPortalNode}
                        printId={routeProps.match.params.printId}
                        routerHistory={routeProps.history}
                    />
                )}
            />
            <Route exact path="/redigera/:siteId/inbjudningar">
                <SelectProduct quickStart={false} siteId={props.siteId!} />
            </Route>
            <Route exact path="/(sms|digital-studentinbjudan)?/snabbstart/inbjudningar">
                <SelectProduct quickStart={true} />
            </Route>
            <Route exact path="/kom-igang/redigera/inbjudningar">
                <SelectProduct quickStart={true} />
            </Route>
        </Switch>
    );
};
